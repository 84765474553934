<template>
  <div>
    <div style="min-height: 4px">
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        :active="pending"
      ></v-progress-linear>
    </div>

    <v-sheet class="pa-4">
      <scores-filter
        :filters="form"
        :in-competence="true"
        @changeFilters="handleChangeFilters"
      />
    </v-sheet>

    <scores-list
      :list="list"
      :ordering="form.ordering"
      class="mb-3"
      :in-competence="true"
      @setOrdering="(v) => (form.ordering = v)"
    />

    <app-pager
      :value="page"
      :total="count"
      :limit="size"
      @input="getList"
    ></app-pager>

    <div v-if="noResults" class="my-3">Нет данных по выбранным параметрам</div>
    <div v-if="error" class="error--text my-3">{{ error }}</div>
  </div>
</template>

<script>
import ScoresFilter from "@/components/scores/ScoresFilter.vue";
import ScoresList from "@/components/scores/ScoresList.vue";
import { apiClient } from "@/api";
import dayjs from "@/plugins/dayjs";

const initialForm = () => {
  return {
    search: "",
    hide_zeros: true,
    user_id: null,
    ordering: "score",
    year: new Date().getFullYear(),
  };
};
export default {
  name: "Scores",
  components: {
    ScoresList,
    ScoresFilter,
  },
  data() {
    return {
      pending: false,
      page: 1,
      size: 25,
      count: 0,
      error: "",
      form: {
        ...initialForm(),
        competence_id: +this.$route.params.id,
      },
      scores: [],
    };
  },
  computed: {
    list() {
      return this.scores.map((scores) => {
        const details = {
          stepik: scores.details?.stepik?.score,
          kaggle: scores.details?.kaggle?.score,
          git_repo: scores.details?.git_repo?.score,
          stackoverflow: scores.details?.stackoverflow?.score,
        };
        return {
          ...scores,
          details,
          all_details: scores.details,
          created_at: dayjs(scores.created_at).format("DD.MM.YYYY HH:mm"),
        };
      });
    },
    noResults() {
      return !this.pending && !this.error && !this.scores?.length;
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.getList(1);
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    handleChangeFilters(payload = {}) {
      console.log("payload", payload);
      if (!payload || (payload && typeof payload !== "object")) return;
      this.form = Object.assign(this.form, payload);
    },
    async getList(page = 1) {
      const { form } = this;
      this.pending = true;
      this.error = "";
      const reqParams = Object.entries(form).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});
      try {
        // @see https://calcon.test.kruzhok.org/api/swagger/index.html#/scores/get_api_scores
        const { data } = await apiClient({
          method: "GET",
          url: "/scores",
          params: {
            limit: this.size,
            offset: (page - 1) * this.size,
            ...reqParams,
          },
        });
        this.scores = data.results;
        this.page = page;
        this.pending = false;
        this.count = data.count;
      } catch (error) {
        this.error = `Не удалось получить список баллов. ${error.message}`;
        this.pending = false;
        this.scores = [];
      }
    },
    async init() {
      if (!this.$store.state.competences?.length) {
        await this.$store.dispatch("getAllCompetence");
      }
      await this.getList();
    },
  },
};
</script>

<style></style>
